import React from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../layout/Layout';
import { Event, gaCategory, gaAction } from '../../utils/analytics';

import { Grid, Fab } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import backgroundImage1 from "../../images/home_hero_bg.svg";
import backgroundImage2 from "../../images/radio-wave-bw.png";
import productScreenshot from "../../images/product_screenshot.png";

import './styles.css';

const Home = ({ auth }) => {

  const history = useHistory();

  const handleMainActionButtonClick = () => {
    history.push('/about');
    Event({ category: gaCategory.USER_INTEREST, action: gaAction.HOME_MAIN_ACTION_BUTTON });
  };

  return (
    <Layout>
      <div
        className='home-page-background'
        style={{
          minHeight: '100vh',
          width: '100vw',
          position: 'relative',
        }}
      >
        {/* Background image 1 without opacity */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage1})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.5,
            zIndex: 1, // Behind the second background
          }}
        ></div>

        {/* Background image 2 with reduced opacity */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage2})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.3,
            zIndex: 2, // Above the first background
          }}
        ></div>

        {/* Main content */}
        <div className="home-page">
          <Grid container spacing={4} alignItems="center">
            <Grid item md={6} xs={12} style={{ textAlign: 'left' }}>
              <h1>AI-assisted Radio-frequency (RF) design and simulation, right on the cloud.</h1>
              <Fab
                variant="extended"
                className="home-action-button"
                style={{
                  textTransform: 'none',
                  marginTop: '2rem',
                  borderRadius: '1px',
                  backgroundColor: '#007BFF',
                  color: 'white',
                }}
                onClick={handleMainActionButtonClick}
              >
                Learn more
                <ArrowForwardIosIcon style={{ marginLeft: '0.5rem' }} />
              </Fab>
            </Grid>
            <Grid item md={6} xs={12} style={{ textAlign: 'center' }}>
              <div
                style={{
                  position: 'relative',
                  transform: 'perspective(800px) rotateY(-15deg) rotateX(5deg)',
                  display: 'inline-block',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    left: '55px',
                    right: '55px',
                    bottom: '-5px',
                    border: '2px solid rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                  }}
                ></div>
                <img
                  src={productScreenshot}
                  alt="Product Screenshot"
                  style={{
                    maxWidth: '80%',
                    borderRadius: '10px',
                    position: 'relative',
                    zIndex: 2,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
